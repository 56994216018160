
import { search } from '@/api/service'

export default {
    namespace:'searchlist',
    state:{
        searchReslist:new Map(),
        searchCategoryList:[]
    },
    effects:{
        *searchListEffects({payload},{call,put,select}){
            console.log('param',payload)
            const param = {
                q: payload.q,
                size: payload.size,
                category_id: payload.categoryId,
                from:payload.from,
                type:payload.type
              };
              // categoryListParams.setCategoryId(categroy.get('categroyId'));
        
              try {
                const data = yield call(
                    search,
                    param,
                );
        
                yield put({
                  type: 'changeSearchList',
                  payload: {url:payload.url,...param,...data},
                });
               
                payload.resolve(data);
              } catch (error) {
                payload.reject('error');
              }
        }
    },
    reducers:{
        changeSearchList(state, { payload }) {
            const res = state.searchReslist.get(payload.url) || {}
            const newObj = {...payload}
            if(res.from !== payload.from || res.category_id !== payload.category_id || res.q !== payload.q) {
              newObj.spu_list = [...res.spu_list||'',...newObj.spu_list]
            }else{
              newObj.spu_list = res.spu_list
            }
            const newMap = state.searchReslist.set(payload.url,newObj)
            console.log('newMap====',newMap);
            return {
              ...state,
              searchReslist: newMap,
            };
          },
          changeSearchCategoryList(state, { payload }) {
            return {
              ...state,
              searchCategoryList: payload,
            };
          },
    }
}