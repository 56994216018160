import { queryDetailData,queryDescription } from '../../../api/service';
import { Map,toJS } from 'immutable';
import { history } from 'umi';
export default {
  //命名空间
  namespace: 'DetailData',
  state: {
    //初始数据
    DetailData:'',
    DescriptionData:'',
    flagGu:true,
    IsActivityProductAdd:false
  },
  effects: {
    //管理异步操作
    *getDetailEffects({ payload }, { call, put, select }) {
      //call 调用异步请求
    //   const data = yield call(queryHomeData, params);
      const data = yield call(queryDetailData, payload);
      //put 发出一个 Action，调用reducers里的方法，类似于 dispatch
      console.log(data,'-----data')
      yield put({
        // 调用当前 model 的 action 不需要添加 namespace
        type: 'changeDetailData',
        payload: data,
      });
      if(data?.data?.type == 2){
        history.push('/page-404')
      }
    },
    *getDescription({ payload }, { call, put, select }) {
      const data = yield call(queryDescription, payload);
      yield put({
        type: 'changeDescription',
        payload: data.data,
      });
    }
  },
  reducers: {
    //管理同步方法接收 action，同步更新 state
    changeDetailData(state, { payload }) {
      console.log(payload,'========0000000')
      return {
        ...state,
        DetailData: payload,
        flagGu:false
      };
    },
    changeDescription(state,{payload})
    {
      return {
        ...state,
        DescriptionData:payload
      }
    },
    changeIsActivityProductAdd(state,{payload}){
      return{
        ...state,
        IsActivityProductAdd:payload
      }
    }
  },
};
