import AWS from 'aws-sdk';
import DeviceDetector from "device-detector-js";
import moment from 'moment';
import {GetCookie,SetCookie,Uuid} from './utils';

let bucketRegion = '';
let IdentityPoolId = '';
let streamName = '';

/** 
const defaultNovilandAnaRecords = ()=> {
  return {
    amount:0,
    length:0,
    records:[]
  };
}

window.NovilandAnaRecords = window.NovilandAnaRecords || defaultNovilandAnaRecords();
const maxAmount = 1024 * 500; // 超过多大必须发送
const maxLength = 1; // 超过多少条必须发送
const intervalTime = 60 * 1000; //每隔多长时间发送一次
***/
const ANALYTICE_COOKIE_NAME = 'NOVILAND_ANALYTICE_ID';

let clientDevice = '';
(function(){
  if(process.env.UMI_ENV == 'production'){
    bucketRegion = 'us-west-1';
    IdentityPoolId = 'us-west-1:61dcbf36-19d0-40e0-966d-300a5b028199';
    streamName = 'modCart-NA';
  }else{
    bucketRegion = 'us-west-1';
    IdentityPoolId = 'us-west-1:61dcbf36-19d0-40e0-966d-300a5b028199';
    streamName = 'test-data-striming';
  }
  const deviceDetector = new DeviceDetector();
  clientDevice = JSON.stringify(deviceDetector.parse(navigator.userAgent));

  AWS.config.region = bucketRegion; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
     IdentityPoolId: IdentityPoolId,
  });
})();

AWS.config.credentials.get(function(err) {
  // attach event listener
  if (err) {
      console.error(err);
      return;
  }
});
const kinesis = new AWS.Kinesis({
  apiVersion: '2013-12-02'
});

/***
 * 发送内容给 aws kinesis
 * records:[
 *    {
 *      Data:JSON.stringify({
 *        platform:'noviland-shop', // 平台标识
 *        ipAddress:'', //用户ip
 *        macAddress:'', //用户的mac 地址
 *        cookieId:'', //用户的 cookie Id
 *        deviceType:'', //用户设备类型
 *        currentUrl:'', //当前url
 *        referrerUrl:'', //从哪个url跳转过来
 *        time:0, // 当前时间 int
 *        eventType:'', //事件类型 onClick,onLoad,onError ...等;
 *        eventContent:'',//事件内容
 *      }),
 *      PartitionKey: 'partition-' + AWS.config.credentials.identityId
 *      
 *      
 *    }
 * 
 * ]
 * 
 */

export const NAEventType = {
  "pageview":{
    type:'pageview',
    content:{
      action:''
    }
  },
  "screenview":{type:'screenview'},
  "event":{type:'event',content:{
    category:'', // click, 
    label:'',
  }},
  "transaction":{type:'transaction'},
  "item":{type:'item'},
  "social":{type:'social'},
  "exception":{type:'exception',content:''},
  "timing":{type:'timing'},
  "request":{type:'request',content:{category:'',link:'',data:''}}
}

export function send(records){
  return new Promise((resolve,reject)=>{
    kinesis.putRecords({
      Records: records,
      StreamName: streamName
    }, function(err, data) {
        if (err) {
          reject(err);
        }else{
          resolve();
        }
    });
  })
}

function getOrSetClientId(){
  let clientId = GetCookie(ANALYTICE_COOKIE_NAME);
  if(!clientId){
    clientId = 'NA_'+Uuid(25,52);
    const cookieExpires = 1E3 * 63072E3; //2 年
    SetCookie(ANALYTICE_COOKIE_NAME,clientId,'/',(new Date((new Date).getTime() + cookieExpires)).toGMTString());
  }
  return clientId;
}

function getScreenParams(){
  let result = {};
  const screen = window.screen;
  let clientWidth = '';
  let clientHeight = '';
  if(document.documentElement){
    clientWidth = document.documentElement.clientWidth;
    clientHeight = document.documentElement.clientHeight;
  }
  if(screen){
    result = {
      screenResolution:screen.width + 'x' + screen.height,
      screenColors:screen.colorDepth + '-bit',
      viewportSize:clientWidth +'x' +clientHeight,
    }
  }
  return result;
}



function getLoginId(){
  const loginId = GetCookie('MODCART_USER_UUID');
  return !loginId?'':loginId;
}

function getZipCode(){
  let zipCode = GetCookie('zip_code');
  if(!zipCode){
    zipCode = GetCookie('zip_code_invalid');
  }
  return zipCode;
}

function getChannel(){
  let channel_val = GetCookie('channel_val');
  if(!channel_val){
    channel_val = '';
  }
  return channel_val;
}

export function generateDefaultData(){
  const navigator = window.navigator;
  const {screenResolution,
         screenColors,
         viewportSize} = getScreenParams();
  const language = (navigator && (navigator.language || navigator.browserLanguage) || '').toLowerCase();
  const encoding =  document.characterSet || document.charset;
  const date = new Date();
  return{
    platform:'noviland-modCart-mobile',
    clientId:getOrSetClientId(),
    loginId:getLoginId(),
    loginType:'Buyer',
    zipCode:getZipCode(),
    channel:getChannel(),
    deviceType:clientDevice, 
    screenResolution:screenResolution,
    screenColors:screenColors,
    language:language,
    encoding:encoding,
    viewportSize:viewportSize,
    currentUrl:window.location.href,
    referrerUrl:document.referrer,
    eventType:'',
    eventContent:'',
    clientTime:moment().format('YYYY-MM-DD HH:mm:ss'),
    timeZoneOffset:date.getTimezoneOffset() / -60,
  }
}

export function sendRecord({type,content,zipCode}){
  let msg = generateDefaultData();
  msg.eventType = type;
  msg.eventContent = content;
  if(zipCode){
    msg.zipCode = zipCode;
  }
  return send([{Data:JSON.stringify(msg),
                PartitionKey: 'partition-'}])
  
}

/*
export function addRecord(record){
  let defaultData = generateDefaultData();
  defaultData.eventType = record.eventType;
  defaultData.eventContent = record.eventContent;
  defaultData.time = moment().valueOf();

  const data = JSON.stringify(defaultData);
  const dataAmount = data.length;
  window.NovilandAnaRecords.amount += dataAmount;
  window.NovilandAnaRecords.length += 1;
  window.NovilandAnaRecords.records.push(data);

  if(window.NovilandAnaRecords.length >= maxLength ||
     window.NovilandAnaRecords.amount >= maxAmount){
    sendGlobal();
  }
}**/
/** 
export function sendGlobal(){
  
  const records = window.NovilandAnaRecords.records.map((item)=>{
    return {
      Data:item,
      PartitionKey: 'partition-'
    }
  });
  window.NovilandAnaRecords =  defaultNovilandAnaRecords();

  if(records.length > 0 ){
    send(records).then(()=>{
      
    }).catch((err)=>{
      console.log(err);
    });
  }
}
***/
/*
window.setInterval(()=>{
  sendGlobal();
},intervalTime);
***/

