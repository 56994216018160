import { useHistory } from 'umi'
import { getAllList } from '../../../api/service'
export default {
    namespace:'everydayExtrasList',
    state:{
        products:[],
        pageNo:0,
        hasMore:true
    },
    effects:{
       *init({ payload }, { call, put, select }){
            const param = {
                "pageNo":1,
                "pageSize":10,
                "categoryId":2080
            }
            try {
                const data = yield call(getAllList,param)
                yield put({
                    type:'initData',
                    payload:data
                })
                
            } catch (error) {
                console.log(error)
            }  
       }, 
       *queryExtrasPage({ payload }, { call, put, select }){
        const param = {
            "pageNo":payload.pageNo,
            "pageSize":10,
            "categoryId":2080
        }
        try {
            const {data} = yield call(getAllList,param)
            yield put({
                type:'changeHasMore',
                payload:data
            })
            yield put({
                type:'changeExtrasList',
                payload:data
            })
            payload.resolve(data);
        } catch (error) {
            payload.reject('error');
        }  
       }
    },
    reducers:{
        initData(state,{payload}){
            return{
                ...state,
                ...payload
            }
        },
        changeExtrasList(state,{payload}){
            const res = {...state} || {};
            const newObj = { ...payload };
            if (
                res.pageNo !== payload.pageNo
            ) {
                console.log('then====不一样');
                newObj.products = [...(res.products || ''), ...newObj.products];
            } else {
                console.log('then====一样');
                newObj.products = res.products;
            }
            return {
                ...state,
                ...newObj
            };
        },
        changeHasMore(state,{payload}){
            console.log('changeHasMore:',payload);
            const bool = (payload.total / payload.pageSize) > payload.pageNo
            return {
                ...state,
                hasMore:bool
            }
        }
    }
}