import {Map,List,fromJS} from 'immutable';
import {pullBuyerExistsAuPaymentProfile,
        saveAuthorizeCard} from '@/api/service';
import {history} from 'umi';
import CryptoJS  from "crypto-js";

const defaultState = Map({
  pulled:false,
  paymentForm:null,
  form:Map({
    saveForFuture:0,
    setDefault:1
  }),
  existsPayment:false,
  hiddenSetDefault:false,
  showError:false,
  errorMsg:'',
  submitting:false,
  showSubmittingError:false,
  submittingErrorMsg:'',
  checkoutClick:false
});

export default {
  namespace:'authorizePaymentAdd',
  state:defaultState,
  reducers:{
    _setPaymentForm(state,{payload}){
      state = state.set('paymentForm',payload.value);
      return state;
    },
    _changeForm(state,{payload}){
      const key = Object.keys(payload)[0];
      state = state.setIn(['form',key],payload[key]);
      return state;
    },
    _showError(state,{payload}){
      state = state.set('showError',payload.showError);
      state = state.set('errorMsg',payload.errorMsg);
      return state;
    },
    _pulled(state,{payload}){
      state = state.set('pulled',payload.value);
      return state;
    },
    _hiddenSetDefault(state,{payload}){
      state = state.set('hiddenSetDefault',payload.value);
      return state;
    },
    _existsPayment(state,{payload}){
      state = state.set('existsPayment',payload.value);
      return state;
    },
    _submitting(state,{payload}){
      state = state.set('submitting',payload.value);
      return state;
    },
    _submittingError(state,{payload}){
      state = state.set('showSubmittingError',payload.showError);
      state = state.set('submittingErrorMsg',payload.errorMsg);
      return state;
    },
    _cleanRedux(state){
      state = defaultState;
      return state;
    },
    _changeClick(state,{payload}){
      console.log('444444444',payload)
      state = state.set('checkoutClick',payload);
      return state
    }
  },
  effects:{
    *setCheckoutClick({payload},{put,call}){
      yield put({type:"_changeClick",payload})
    },
    *setPaymentForm({payload},{put,call}){
      yield put({type:'_setPaymentForm',payload});
    },
    *changeForm({payload},{put,select}){
      yield put({type:'_changeForm',payload});

      const {existsPayment,
             } = select(({authorizePaymentAdd})=>{
        return {
          existsPayment:authorizePaymentAdd.get('existsPayment'),

        }
      })

      // 如果修改的是 save for future 需要隐藏 set default
      const key = Object.keys(payload)[0];
      if(key == 'saveForFuture'){
        yield put({type:'adjugementhiddenSetDefault'});
      }
    },
    *adjugementhiddenSetDefault({payload},{put,call,select}){
      const {existsPayment,
             saveForFuture
             } = yield select(({authorizePaymentAdd})=>{
        return {
          existsPayment:authorizePaymentAdd.get('existsPayment'),
          saveForFuture:authorizePaymentAdd.getIn(['form','saveForFuture'])
        }
      });
      
      if(existsPayment && 
         saveForFuture == 1){
        yield put({type:'_hiddenSetDefault',payload:{value:false}});
      }else{
        yield put({type:'_hiddenSetDefault',payload:{value:true}});
      }

    },
    *pullData({payload},{put,call}){
      
      try{
        const response = yield call(pullBuyerExistsAuPaymentProfile);
        if(response.status != 'success'){
          throw new Error(response.errorMsg);
        }
        if(response.data.exists == 1){
          yield put.resolve({type:'_existsPayment',payload:{value:true}});
        }
        yield put.resolve({type:'adjugementhiddenSetDefault'});

      }catch(err){
        yield put({type:'_showError',payload:{showError:true,errorMsg:err.message}});
      }finally{
        yield put({type:'_pulled',payload:{value:true}});
      }
    },
    *submit({payload},{put,call,select}){
      const {
        paymentForm,
        form,

      } = yield select(({authorizePaymentAdd})=>{
        return {
          paymentForm:authorizePaymentAdd.get('paymentForm'),
          form:authorizePaymentAdd.get('form')
        }
      });
      
      yield put({type:'_submittingError',payload:{showError:false,errorMsg:''}});

      if(form.get('saveForFuture')=='1'){
        yield put({type:'submitCustomerPaymentProfile'});
      }else{
        yield put({type:'submitForAcceptPayment'});
      }
      
    },
    *submitForAcceptPayment({payload},{put,select,call}){
      
      const {
        paymentForm,
        form,

      } = yield select(({authorizePaymentAdd})=>{
        return {
          paymentForm:authorizePaymentAdd.get('paymentForm'),
          form:authorizePaymentAdd.get('form')
        }
      });
      if(!paymentForm.validateAll()){
        return;
      }
      yield put({type:'_submitting',payload:{value:true}});
      try{
        const response = yield call(paymentForm.submitHandler);
        const locationQuery = history.location.query;
        let callBack = locationQuery.call_back;
        if(callBack){
          callBack = decodeURIComponent(callBack);
          const query = history.location.query;
          history.push({pathname:callBack,query:{
            ...query,
            "credit-card":encodeURIComponent(
                           CryptoJS.AES.encrypt(
                            JSON.stringify(
                              {...response,
                                type:'card_form_accept_payment'
                              }
                            ),
                           'secret key Noviland').toString())
          }});
        }else{
          history.go(-1);
        }
        
      }catch(err){
        yield put({type:'_submittingError',payload:{showError:true,errorMsg:err.message}});
      }
      
      yield put({type:'_submitting',payload:{value:false}});
    },
    *submitCustomerPaymentProfile({payload},{put,select,call}){
      const {
        paymentForm,
        form,

      } = yield select(({authorizePaymentAdd})=>{
        return {
          paymentForm:authorizePaymentAdd.get('paymentForm'),
          form:authorizePaymentAdd.get('form')
        }
      });
      if(!paymentForm.validateAll()){
        return;
      }
      yield put({type:'_submitting',payload:{value:true}});
      try{
        const cardForm = paymentForm.state.value;
        const [month, year] = cardForm.expDate.split('/')
        const params = {isDefault:form.get('setDefault','0'),
        cardNumber:cardForm['cardNum'].replace(/\s/g, ''),
        month,
        year}
        const card_info = encodeURIComponent(
          CryptoJS.AES.encrypt(
           JSON.stringify(
             {...params,
               type:'card_form_accept_payment'
             }
           ),
          'secret key Noviland').toString())
        const response = yield call(saveAuthorizeCard,{card_info});
        if(response['status']!='success'){
          throw new Error(response.message);
        }
        paymentForm.cleanForm();

        const locationQuery = history.location.query;
        let callBack = locationQuery.call_back;
        if(callBack){
          
          callBack = decodeURIComponent(callBack);
          const query = history.location.query;
          history.push({pathname:callBack,query:{
            ...query,
            "credit-card":encodeURIComponent(
                           CryptoJS.AES.encrypt(
                            JSON.stringify(
                              {
                                paymentProfileId:response['data']['paymentProfileId'],
                                customerProfileId:response['data']['customerProfileId'],
                                type:'card_form_payment_profile'
                              }
                            ),
                           'secret key Noviland').toString())
          }});

        }else{
          history.go(-1);
        }
      }catch(err){
        yield put({type:'_submittingError',payload:{showError:true,errorMsg:err.message}});
      }finally{
        yield put({type:'_submitting',payload:{value:false}});
      }

    },
    *cleanRedux({payload},{put}){
      yield put({type:'_cleanRedux'});
    }
  }
}