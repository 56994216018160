// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/node_modules/.store/dva-loading@3.0.24/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelIndex0 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/addressBook/models/index.js';
import ModelIndex1 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/addressEdit/models/index.js';
import ModelIndex2 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/authorizePaymentAdd/models/index.js';
import ModelIndex3 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/authorizePaymentList/models/index.js';
import ModelModel4 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/base/models/model.js';
import ModelModel5 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/categoryGoodList/models/model.js';
import ModelAddress6 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/checkOrder/models/address.js';
import ModelIndex7 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/checkOrder/models/index.js';
import ModelModels8 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/details/models/models.js';
import ModelModel9 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/everydayExtrasList/models/model.js';
import ModelModel10 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/favoritesList/models/model.js';
import ModelLiving11 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/home/models/living.js';
import ModelModel12 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/home/models/model.js';
import ModelZipCode13 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/home/models/zipCode.js';
import ModelIndex14 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/instagram/models/index.js';
import ModelLoginQuick15 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/login/models/loginQuick.js';
import ModelModel16 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/login/models/model.js';
import ModelModel17 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/mine/models/model.js';
import ModelModel18 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/myCart/models/model.js';
import ModelModel19 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/orderDetail/models/model.js';
import ModelModel20 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/orderHistory/models/model.js';
import ModelModel21 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/orderThanks/models/model.js';
import ModelModel22 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/paymentManage/models/model.js';
import ModelModel23 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/resetPassword/models/model.js';
import ModelModel24 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/searchResult/models/model.js';
import ModelModel25 from 'D:/codeBase/ModCartMobileFrontend/FrontendNewModCart/src/pages/themelist/models/model.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'index', ...ModelIndex0 });
app.model({ namespace: 'index', ...ModelIndex1 });
app.model({ namespace: 'index', ...ModelIndex2 });
app.model({ namespace: 'index', ...ModelIndex3 });
app.model({ namespace: 'model', ...ModelModel4 });
app.model({ namespace: 'model', ...ModelModel5 });
app.model({ namespace: 'address', ...ModelAddress6 });
app.model({ namespace: 'index', ...ModelIndex7 });
app.model({ namespace: 'models', ...ModelModels8 });
app.model({ namespace: 'model', ...ModelModel9 });
app.model({ namespace: 'model', ...ModelModel10 });
app.model({ namespace: 'living', ...ModelLiving11 });
app.model({ namespace: 'model', ...ModelModel12 });
app.model({ namespace: 'zipCode', ...ModelZipCode13 });
app.model({ namespace: 'index', ...ModelIndex14 });
app.model({ namespace: 'loginQuick', ...ModelLoginQuick15 });
app.model({ namespace: 'model', ...ModelModel16 });
app.model({ namespace: 'model', ...ModelModel17 });
app.model({ namespace: 'model', ...ModelModel18 });
app.model({ namespace: 'model', ...ModelModel19 });
app.model({ namespace: 'model', ...ModelModel20 });
app.model({ namespace: 'model', ...ModelModel21 });
app.model({ namespace: 'model', ...ModelModel22 });
app.model({ namespace: 'model', ...ModelModel23 });
app.model({ namespace: 'model', ...ModelModel24 });
app.model({ namespace: 'model', ...ModelModel25 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
