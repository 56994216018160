import {Map,List,fromJS} from 'immutable';
import {pullAuthorizePayments,setDefaultPayment,deletePaymentApi} from '@/api/service.js';

const defaultState = Map({
  payments:List([]),
  deleteInfo:Map({
    showError:false,
    errorMsg:'',
    showConfirm:false,
    paymentId:'',
    profileId:'',
  }),
  infoPopup:Map({
    show:false,
    message:'',
    icon:'',
  }),
  pulled:false,
  loading:false,
  showError:false,
  errorMsg:'',
});
export default {
  namespace:'paymentList',
  state:defaultState,
  reducers:{
    _initialState(state,{payload}){
      state = defaultState;
      return state;
    },
    _setPayment(state,{payload}){
      state = state.set('payments',payload.payments);
      return state;
    },
    _loading(state,{payload}){
      state = state.set('loading',payload.value);
      return state;
    },
    _showError(state,{payload}){
      state = state.set('showError',payload.showError);
      state = state.set('errorMsg',payload.errorMsg);
      return state;
    },
    _pulled(state,{payload}){
      state = state.set('pulled',payload.value);
      return state;
    },
    _closeInfoPopup(state,{payload}){
      state = state.set('infoPopup',defaultState.get('infoPopup'));
      return state;
    },
    _showInfoPopup(state,{payload}){
      state = state.set('infoPopup',Map({...payload,show:true}));
      return state;
    },
    _changeDeleteConfirm(state,{payload}){
      state = state.set('deleteInfo',Map({...payload}));
      return state;
    }
  },
  effects:{
    *initialState({payload},{put,select}){
      yield put({type:'_initialState',payload});
    },
    *pullData({payload},{put,select,call}){
      
      yield put({type:'_showError',payload:{showError:false,errorMsg:''}});
      yield put({type:'_loading',payload:{value:true}});
      try{
        const response = yield call(pullAuthorizePayments);
        if(response['status']!='success'){
          throw new Error(response['message']);
        }
        const payments = fromJS(response.data.list);
        yield put({type:'_setPayment',payload:{payments}});
      }catch(err){
        yield put({type:'_showError',payload:{showError:true,errorMsg:err.message}});
      }finally{
        yield put({type:'_loading',payload:{value:false}});
        yield put({type:'_pulled',payload:{value:true}});
      }
    },
    *setDefault({payload},{put,select,call}){
      const {paymentId,profileId} = payload;
      try{
        const response = yield call(setDefaultPayment,{paymentId,profileId});
        if(response.status!='success'){
          throw new Error(response.message);
        }else{
          yield put({type:'pullData'});
        }
        yield put({type:'_showInfoPopup',payload:{message:'Successfully updated',icon:'success'}})
      }catch(err){
        yield put({type:'_showInfoPopup',payload:{message:err.message,icon:'error'}})
      }
    },
    *closeInfoPopup({payload},{put,select,call}){
      yield put({type:'_closeInfoPopup'});
    },
    *changeDeleteConfirm({payload},{put,select,call}){
      yield put({type:'_changeDeleteConfirm',payload});
    },
    *deletePayment({payload},{put,select,call}){
      const {
        deleteInfo
      } = yield select(({paymentList})=>{
        return {
          deleteInfo:paymentList.get('deleteInfo')
        }
      })
      try{
        const response = yield call(deletePaymentApi,{paymentId:deleteInfo.get('paymentId'),profileId:deleteInfo.get('profileId')});
        yield put({type:'changeDeleteConfirm',payload:{paymentId:0,profileId:0,showConfirm:false}});
        if(response.status!='success'){
          throw new Error(response.message);
        }else{
          yield put({type:'pullData'});
        }
        yield put({type:'_showInfoPopup',payload:{message:'Successfully updated',icon:'success'}})
      }catch(err){
        yield put({type:'_showInfoPopup',payload:{message:err.message,icon:'error'}})
      }
    }
  }
}