import pullInitialReq from '@/proto/shop/product/buyer/shopProductPullInitialData_pb';
import { List } from 'immutable';
import {
  queryfirstCategoryList,
  queryCategoryList,
  queryCategoryThreeList,
} from '../../../api/service';
export default {
  namespace: 'categoryData',
  state: {
    pageScroll: new Map(),
    categoryFirstList: {},
    categoryList: {},
    categoryThreeList: new Map(),
    pageNo:1,
  },
  effects: {
    *init({ payload }, { call, put, select }){
      console.log('init==payload', payload);
      const categroy = {
        pageNo: 1,
        pageSize: payload.pageSize,
        categoryId: payload.categoryId,
      };
      // categoryListParams.setCategoryId(categroy.get('categroyId'));

      try {
        const data = yield call(queryCategoryList, categroy);

        yield put.resolve({
          type: 'initCategoryList',
          payload: { ...data, ...categroy },
        });
        yield put.resolve({
          type:'changepageNo',
          payload:{...categroy}
        })
      } catch (error) {
      }
    },
    *getCategoryProductListEffects({ payload }, { call, put, select }) {
      console.log('getCategoryProductListEffects==payload', payload);
      const {pageNo,categoryList} =yield select((state) => state.categoryData)
      const categroy = {
        pageNo:payload.categoryId == categoryList.categoryId ? pageNo : 1,
        pageSize: payload.pageSize,
        categoryId: payload.categoryId,
      };
      // categoryListParams.setCategoryId(categroy.get('categroyId'));

      try {
        const data = yield call(queryCategoryList, categroy);

        yield put.resolve({
          type: 'changeCategoryList',
          payload: { ...data, ...categroy },
        });
        yield put.resolve({
          type:'changepageNo',
          payload:{...categroy}
        })
        payload.resolve(data);
      } catch (error) {
        payload.reject('error');
      }
    },
    *getCategoryThreeListEffects({ payload }, { call, put, select }) {
      console.log('payload', payload);
      const categroy = {
        pageNo: payload.pageNo,
        pageSize: payload.pageSize,
        categoryId: payload.categoryId,
      };
      // categoryListParams.setCategoryId(categroy.get('categroyId'));

      try {
        const data = yield call(queryCategoryThreeList, categroy);

        yield put({
          type: 'changeCategoryThreeList',
          payload: { url: payload.url, ...categroy, ...data },
        });

        payload.resolve(data);
      } catch (error) {
        payload.reject('error');
      }
    },
    *getCategoryFirstListEffects({ payload }, { call, put, select }) {
      console.log('payload', payload);
      const categroy = {
        pageNo: payload.pageNo,
        pageSize: payload.pageSize,
      };
      // categoryListParams.setCategoryId(categroy.get('categroyId'));

      try {
        const data = yield call(queryfirstCategoryList, categroy);

        yield put({
          type: 'changeCategoryFirstList',
          payload: data,
        });

        payload.resolve(data);
      } catch (error) {
        payload.reject('error');
      }
    },
  },
  reducers: {
    changepageNo(state, { payload }){
      console.log('记录路由',payload)
      return {
        ...state,
        pageNo:payload.pageNo+1
      }
    },
    initCategoryList(state, { payload }){
      return {
        ...state,
        categoryList: {...payload},
      }
    },
    changeCategoryList(state, { payload }) {
      const thrC = JSON.parse(JSON.stringify(state.categoryList?.thrCategoryDetail||[]));
      const newObj = { ...payload };
      if(state.categoryList?.categoryId == payload.categoryId){
        newObj.thrCategoryDetail = [
          ...thrC,
          ...newObj.thrCategoryDetail,
        ];
      }else{
        newObj.thrCategoryDetail = [
          ...newObj.thrCategoryDetail,
        ];
      }
      console.log('changeCategoryList===', payload);
      return {
        ...state,
        categoryList: newObj,
      };
    },
    changeCategoryThreeList(state, { payload }) {
      const res = state.categoryThreeList.get(payload.url) || {};
      const newObj = { ...payload };
      if (
        res.categoryId !== payload.categoryId ||
        res.pageNo !== payload.pageNo
      ) {
        console.log('then====不一样');
        newObj.products = [...(res.products || ''), ...newObj.products];
      } else {
        console.log('then====一样');
        newObj.products = res.products;
      }
      const newMap = state.categoryThreeList.set(payload.url, newObj);
      
      return {
        ...state,
        categoryThreeList: newMap,
      };
    },
    //url => categoryListData
    changeCategoryFirstList(state, { payload }) {
      const twoC = state.categoryFirstList?.twoCategoryDetail;
      const newObj = { ...payload };
      if (state.categoryFirstList.pageNo !== payload.pageNo) {
        newObj.twoCategoryDetail = [
          ...(twoC || ''),
          ...newObj.twoCategoryDetail,
        ];
      } else {
        newObj.twoCategoryDetail = twoC;
      }
      console.log('记录路由payload===', newObj);
      return {
        ...state,
        categoryFirstList: newObj,
      };
    },

    changePageScroll(state, { payload }) {
      // console.log('changePageScroll',payload)
      const obj = state.pageScroll.set(payload.url, payload.scroll_pos);
      return {
        ...state,
        pageScroll: obj,
      };
    },
  },
};
