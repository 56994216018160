import { orderThanksDetail} from '../../../api/service';
import { Map,List } from 'immutable'
export default {
  namespace: 'orderThanksDetail',
  state: {
    detailData: Map({}),
  },
  effects: {
      *getDetailDataEffect({ payload }, { call, put, select }) {
        let params = {
              orderUuid:payload.value
        }
        const data = yield call(orderThanksDetail,params)
        console.log('data=1111=详情',data)
        yield put({
            type:'changeDetailData',
            payload:data.data
        })
      },
  },
  reducers: {
    changeDetailData(state, { payload }) {
      console.log(payload)
      return {
        ...state,
        detailData: payload,
      };
    },
  }
};
