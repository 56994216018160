import {Map} from 'immutable';
import {pullInstagramData} from '@/api/service';
import {history} from 'umi';

const defaultState = Map({
    loading:false,    
});

export default {
    namespace:'instagram',
    state:defaultState,
    reduces:{
        _changeLoading(state,{payload}){
            state = state.set(loading,payload.value);
            return state;
        }
    },
    effects:{
       *pullData({payload},{put,call}){
           yield put({type:'_changeLoading',payload:{value:true}});
           try{
               const response = yield call(pullInstagramData);
               console.log(response);
               if(response['status']!='success'){
                   throw response.msg;
               }else{
                   if(!response['data']['isLogin']){
                       history.replace('/newuser?from-ins=1');
                   }else{
                       history.replace('/');
                   }
               }
           }catch(e){
               console.error(e);
               history.replace('/');
           }finally{
               yield put({type:'_changeLoading',payload:{value:false}});
           }
       }   
    }
}