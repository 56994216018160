'use strict'

import {Map,List,fromJS} from 'immutable';

const defaultState = Map({
  payMethod:'creditCard',//creditCard paypal
});

export default {
  namespace:'checkout',
  state:defaultState,
  reducers:{
    _setPayMethod(state,{payload}){
      state = state.set('payMethod',payload.payMethod);
      return state;
    }
  },
  effects:{
    *setPayMethod({payload},{call,put,select}){
      yield put({type:'_setPayMethod',payload});
    }
  }
}