import { zipcodePull,zipCodePush} from '../../../api/service';
import { Map,List } from 'immutable'
export default {
  namespace: 'zipCode',
  state: {
    zipCodeData: {status:'error'},
  },
  effects: {
      *getData({ payload }, { call, put, select }) {
       
        let params = {
          zipCode:payload.value
        }
        const data = yield call(zipcodePull,params)
        
      //localStorage
      if(data.status == 'success'){
        let newData = data.data;
        localStorage.setItem('zip_code',newData.zip_code);
        localStorage.setItem('zip_code_city',newData.city);
        localStorage.setItem('zip_code_supported',newData.supported_area);
        if(newData.supported_area == 0){
          localStorage.setItem('zip_code_invalid',newData.zip_code);
        }
        localStorage.setItem('welcome_close',1);
        
      }else{
        
        localStorage.setItem('zip_code_city','Metro Atlanta');
        localStorage.setItem('zip_code_supported',0);
        localStorage.setItem('welcome_close',1);
      }
      

        yield put({
            type:'changeDetailData',
            payload:data
        });
        return data;
      },

      *zipCodePush({ payload }, { call, put, select }) {
        // email_subscribe
        let params = {
          email:payload.value,
          zip_code:localStorage.getItem('zip_code_invalid')
        }
        const data = yield call(zipCodePush,params);    
      // //localStorage
      // if(data.status == 'success'){
      //   let newData = data.data;
      //   localStorage.setItem('zip_code_city',newData.city);
      //   localStorage.setItem('zip_code_supported',1);
      //   localStorage.setItem('welcome_close',1);
      // }else{
      //   localStorage.setItem('zip_code_city','Metro Atlanta');
      //   localStorage.setItem('zip_code_supported',0);
      //   localStorage.setItem('welcome_close',1);
      // }
      

      //   yield put({
      //       type:'changeDetailData',
      //       payload:data
      //   })
      // },
      }
  },
  reducers: {
    changeDetailData(state, { payload }) {
      return {
        zipCodeData: payload,
      };
    },
  }
};
